<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      billingAnalysis: [],
      threeMonth: [],
      twoMonth: [],
      currentMonth: [],
      Creditors: [],
      total: [],
      accountingPeriods: [],
      customers: [],
      amount: 0,
      totalCurrent: 0,
      totalTwoMonth: 0,
      totalThreeMonth: 0,
      limit: 20,
      page: 1,
      totalPages: 0,
      data: {},
    };
  },
  methods: {
    getBillAge(page) {
      this.http
        .post("transactions/age-analysis", {
          limit: this.limit,
          page: page,
          accounting_period_id: this.data.accounting_period_id,
        })
        .then((res) => {
          this.totalTwoMonth = 0;
          this.totalThreeMonth = 0;
          this.billingAnalysis = res.data;
          console.log(this.billingAnalysis);
          this.page = page;
          this.totalPages = Math.ceil(res.tot / this.limit);
          res.data.forEach((bill) => {
            this.totalTwoMonth += parseFloat(bill.two_month) || 0;
          });
          res.data.forEach((bill) => {
            this.totalThreeMonth += parseFloat(bill.three_month) || 0;
          });
        });
    },
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
        }
      });
    },
  },
  created() {
    this.getBillAge(1);
    this.getAccountingPeriods();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('bill_age_analysis_report.title')"
    />
    <form @submit.prevent="getBillAge(page)">
      <div class="row">
        <div class="col">
          <label class="inline" for="fromJobTitle">{{
            $t("report.period")
          }}</label>
          <select
            v-model="data.accounting_period_id"
            type="date"
            class="form-control"
            id="fromJobTitle"
          >
            <option
              :value="period.id"
              v-for="period in accountingPeriods"
              :key="period"
            >
              {{ $t("report.period") + " : " + period?.start_date }}
            </option>
          </select>
        </div>
        <div class="col-auto" style="top: 20px">
          <button type="submit" class="btn btn-primary">
            {{ $t("popups.search") }}
          </button>
        </div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
    </form>
    <ReportsHeader reportName="Bill Age Analysis Report" />

    <table class="table m-0" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <thead>
        <tr class="text-center">
          <td></td>
          <td>{{ totalTwoMonth.toLocaleString() }}</td>
          <td>{{ totalThreeMonth.toLocaleString() }}</td>
          <!-- <td>{{ totalThreeMonth.toLocaleString() }}</td> -->
        </tr>
      </thead>
      <thead>
        <tr
          class="text-center text-light"
          style="background-color: #2a3042 !important"
        >
          <th>{{ $t("bill_age_analysis_report.name") }}</th>
          <th>{{ $t("bill_age_analysis_report.befor30") }}</th>
          <th>{{ $t("bill_age_analysis_report.befor60") }}</th>
          <!-- <th>{{ $t("bill_age_analysis_report.befor90") }}</th> -->
        </tr>
      </thead>
      <tbody>
        <td>
          <tr
            class="d-flex justify-content-center"
            v-for="bill in billingAnalysis"
            :key="bill"
          >
            <td class="text-center">{{ bill.name }}</td>
          </tr>
        </td>
        <td>
          <tr
            class="d-flex justify-content-center"
            v-for="bill in billingAnalysis"
            :key="bill"
          >
            <td class="text-center">
              {{ bill.two_month ? bill.two_month : "-" }}
            </td>
          </tr>
        </td>
        <td>
          <tr
            class="d-flex justify-content-center"
            v-for="bill in billingAnalysis"
            :key="bill"
          >
            <td class="text-center">
              {{ bill.three_month ? bill.three_month : "-" }}
            </td>
          </tr>
        </td>
      </tbody>
    </table>

    <!-- start pagination -->
    <div>
      <!--   Apps  Pagination     -->
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in totalPages"
          :key="p"
        >
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="getBillAge(p)"
            >{{ p }}</a
          >
        </li>

        <li class="page-item" :class="{ disabled: page == totalPages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- end pagination -->
  </Layout>
</template>
<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
</style>
